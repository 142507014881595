<template>
  <!-- 数据报告 -->
  <div class="page-main">
    <search-wrap class="search-wrap">
      <el-form inline label-suffix="：">
        <el-form-item label="报告名称">
          <el-input v-model="search.reportName" placeholder="请输入报告名称" />
        </el-form-item>
        <el-form-item label="创建人名称">
          <el-input v-model="search.creatorName" placeholder="请输入创建人姓名" />
        </el-form-item>
        <el-button @click="onSearch">搜索</el-button>
      </el-form>
      <div class="handle-btn">
        <el-button
          type="primary"
          icon="el-icon-plus"
          plain
          @click="editFormSubmit"
        >
          新建数据报告
        </el-button>
      </div>
    </search-wrap>
    <!-- 处理表格区 -->
    <!-- 表格 -->
    <el-table
      ref="table"
      v-loading="loading"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
      @sort-change="onSortChange"
    >
      <el-table-column prop="reportName" label="报告名称" />
      <el-table-column prop="placeholderList" label="公共占位项">
        <template slot-scope="scoped">
          <el-tag
            v-for="item in scoped.row.placeholderList"
            :key="item"
            type="warning"
            size="mini"
            style="margin-right: 5px;"
          >
            {{ item }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="creatorName"
        align="center"
        label="创建人"
        width="80"
      />
      <el-table-column prop="updatedAt" width="160" label="报告更新日期" />
      <el-table-column prop="createdAt" width="160" label="报告生成日期" />

      <el-table-column label="操作" width="250">
        <template slot-scope="scoped">
          <el-button type="primary" size="mini" @click="onCheck(scoped.row)">查看</el-button>
          <el-button type="danger" size="mini" @click="onDel(scoped.row)">删除</el-button>
          <el-button v-if="scoped.row.status !== 2" size="mini" @click="changeStatus(scoped.row)">发布</el-button>
          <el-button v-if="scoped.row.status === 2" size="mini" @click="changeStatus(scoped.row)">取消发布</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>

    <!-- 弹窗区 -->
    <el-dialog
      title="新建数据报告"
      :visible.sync="editForm.dialogVisible"
      width="80%"
      @open="open"
    >
      <VueScrollbar v-loading="reportModeLoading">
        <ul
          v-infinite-scroll="getReportModelList"
          :infinite-scroll-disabled="isDisabled"
          class="infinite-list"
          style="overflow: auto;"
        >
          <template v-if="reportModelList.length">
            <li v-for="item in reportModelList" :key="item.reportModelId" class="infinite-list-item">
              <el-card shadow="hover" size="small" class="box-card">
                <div slot="header" class="clearfix">
                  <span :title="item.modelName">{{ item.modelName }}</span>
                  <el-button type="text" @click="editFormSubmit(item)">使用该模板</el-button>
                </div>
                <div class="text item">
                  <p>
                    <span class="label">创建人：</span>
                    <span>{{ item.creatorName }}</span>
                  </p>
                  <p>
                    <span class="label">更新时间：</span>
                    <span>{{ item.updatedAt }}</span>
                  </p>
                  <p>
                    <span class="label">被引用了：</span>
                    <span>{{ item.refCount }}</span>
                  </p>
                </div>
              </el-card>
            </li>
          </template>
          <el-empty v-else />
        </ul>
      </VueScrollbar>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editForm.dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="editForm.loading" @click="editFormSubmit">自定义报告模板</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import { reportPageList, reportModelPageList, reportDelete, updateStatus } from '@/apis/reportService.js'
import VueScrollbar from '../../../components/VueScrollbar/index.vue'
export default {
  name: 'DataReportManagement',
  components: { VueScrollbar },
  mixins: [paginationMixin],
  data() {
    return {
      search: {
        reportName: ''
      },
      loading: false,
      isDisabled: false,
      tableData: [],
      sceneList: [],
      editForm: {
        id: '',
        remark: '',
        loading: false,
        dialogVisible: false
      },
      editFormRules: {
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      },
      reportModelList: [],
      moduleParams: {
        pageNo: 1,
        pageSize: 10
      },
      reportModeLoading: false
    }
  },
  computed: {
    editTitle() {
      return this.editForm.id ? '编辑' : '新增'
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    // 获取列表数据
    async getDataList() {
      this.loading = true
      const params = this.getParams(this.search)
      try {
        const { data = [], totalCount } = await reportPageList(params)
        this.loading = false
        this.tableData = data
        this.pagination.total = totalCount
      } catch (error) {
        this.loading = false
      }
    },
    async getReportModelList() {
      if (this.reportModeLoading) return
      try {
        this.reportModeLoading = true
        const { data } = await reportModelPageList(this.moduleParams)

        if (this.moduleParams.pageNo === 1) {
          this.reportModelList = data
        } else {
          this.reportModelList.push(...data)
        }
        if (data.length === this.moduleParams.pageSize) {
          this.moduleParams.pageNo++
        } else {
          this.isDisabled = true
        }
        this.reportModeLoading = false
      } catch (error) {
        this.reportModeLoading = false
        console.log(error)
      }
    },
    open() {
      this.isDisabled = false
      this.moduleParams.pageNo = 1
      this.getReportModelList()
    },
    onAdd() {
      this.editForm.dialogVisible = true
    },
    async onDel({ reportId }) {
      try {
        await this.$confirm('此操作将永久删除该报告, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        const res = await reportDelete({ reportId })
        this.$message.success('删除成功')
        this.getDataList()
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    // 改变状态
    async changeStatus({ status, reportId }) {
      const params = {
        reportId,
        status: status === 0 ? 2 : status === 2 ? 0 : 2
      }
      try {
        const res = await this.$axios.get(this.$API.updateReportStatus, { params })
        console.log(res)
        this.$message.success('操作成功')
        this.getDataList()
      } catch (error) {
        console.log(error)
        this.$message.error(error.message)
      }
    },
    // 表单提交
    editFormSubmit({ reportModelId }) {
      this.editForm.dialogVisible = false
      this.$router.push({
        name: 'ReportCreateOrEdit',
        query: {
          reportModelId
        }
      })
    },
    // 搜索
    queryData() {
      this.pagination.pageNo = 1
      this.getDataList()
    },
    // 编辑
    async onEdit({ reportId, status }) {
      try {
        status = status === 0 ? 1 : status === 1 ? 2 : 0
        console.log(reportId, status)
        const res = await updateStatus({ reportId, status })
        console.log(res)
        this.getDataList()
        this.$message.success('编辑成功')
      } catch (error) {
        console.log(error)
      }
    },
    // 查看
    onCheck(row) {
      this.$router.push({
        name: 'ReportCreateOrEdit',
        query: {
          reportId: row.reportId
        }
      })
    },
    onSearch() {
      this.pagination.pageNo = 1
      this.getDataList()
    }
  }
}
</script>
<style lang="scss" scoped>
// scss
.page-main {
  .search-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}
.handle-btn {
  margin-bottom: 10px;
}
.textarea {
  width: 400px;
}
.pagination-wrap {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
:deep(.el-dialog__body) {
  padding: 10px 20px;
  .infinite-list {
    padding: 0;
    margin: 0;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    .infinite-list-item {
      list-style: none;
      .clearfix {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > span {
          flex: 1;
          flex-shrink: 1;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
        }
      }
    }
  }
}
.dialog-footer {
  text-align: center;
}
</style>
