<template>
  <div class="deal-opt-deep">
    <el-form>
      <el-form-item
        v-for="optionItem in options"
        :key="optionItem.label"
        :label="optionItem.label"
        class="no-wrap"
      >
        <el-cascader
          ref="cascader"
          :value="dealOpt[optionItem.label]?.keyPath.split('.')"
          size="mini"
          :options="optionItem.select"
          :props="optionItem.cascaderProps"
          @input="handleCascaderChange($event, optionItem)"
        />
        <!-- 是否选了 dealOpt[optionItem.label].keyPath -->
        <template v-if="dealOpt[optionItem.label] && dealOpt[optionItem.label].keyPath">
          <!-- 交互类型 -->
          <template v-if="optionItem.interactionType === 'input'">
            <el-input-number
              v-if="getTreeArrayTarget(optionItem, dealOpt[optionItem.label].keyPath).valueVerify.type === 'number'"
              v-model="dealOpt[optionItem.label].value"
              :min="1"
              :step="1"
              step-strictly
              class="input"
              size="mini"
            />
            <template v-else-if="getTreeArrayTarget(optionItem, dealOpt[optionItem.label].keyPath).valueVerify.type === 'number[]'">
              <el-input-number
                v-for="(item, index) in getTreeArrayTarget(optionItem, dealOpt[optionItem.label].keyPath).valueVerify.default"
                :key="index"
                v-model="dealOpt[optionItem.label].value[index]"
                :min="1"
                :step="1"
                step-strictly
                class="input"
                size="mini"
              />
            </template>
          </template>
        </template>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getObjectValue } from '@/components/DataChart/analyze.js'
export default {
  name: 'DealOptDeep',
  components: {},
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {
    dealOpt: {
      get() {
        return this.value
      }
    }
  },
  watch: {
    dealOpt: {
      handler(newVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    if (Object.keys(this.dealOpt).length !== 0) return

    this.options.forEach(item => {
      this.$set(this.dealOpt, item.label, {
        keyPath: '',
        value: ''
      })
    })
  },
  mounted() {},
  methods: {
    getObjectValue,
    dealTreeData(obj) {
      return Object.keys(obj).map(key => {
        let row = {
          name: key
        }
        if (Object.prototype.toString.call(obj[key]) === '[object Object]') {
          obj[key].children = this.dealTreeData(obj[key])
        }
        return row
      })
    },
    valueType(row, path) {
      return Object.prototype.toString.call(getObjectValue(row, path.split('.')))
    },
    // 递归treeArr获取对象值
    getTreeArrayTarget(row, path = '') {
      if (!path) return row
      const parts = path.split('.')
      let result = row.select
      function findRecursive(parts, data) {
        const part = parts.shift()
        const foundItem = data.find(item => item[row.cascaderProps.value] === part)

        if (!foundItem) {
          return null
        }
        if (parts.length === 0) {
          return foundItem
        }
        return findRecursive(parts, foundItem.children)
      }
      return findRecursive(parts, result)
    },
    // 级联选择器change事件
    handleCascaderChange(value, optionItem) {
      if (!this.dealOpt[optionItem.label] || Object.keys(this.dealOpt[optionItem.label]).length === 0) {
        this.$set(this.dealOpt, optionItem.label, {
          keyPath: '',
          value: ''
        })
      }
      this.dealOpt[optionItem.label].keyPath = value?.join('.') || ''

      this.dealOpt[optionItem.label].value = this.getTreeArrayTarget(optionItem, this.dealOpt[optionItem.label]?.keyPath).valueVerify?.default || ''
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.deal-opt-deep {
  .no-wrap {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    .input {
      width: 100px;
    }
  }
}
</style>
