<template>
  <div>
    <EditingTools
      ref="editingTools"
      v-model="dealValue"
      :init-option="initOption"
      class="tinymce-edit"
    />
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      title="编辑"
      width="40%"
      class="mydialog"
      @open="handlerDialogOpen"
    >
      <el-form v-if="Object.keys(relationData).length">
        <el-form-item class="layout">
          从<el-select v-model="activeRow.source" size="mini" class="select">
            <el-option
              v-for="item in relationData.desc.groupByDesc"
              :key="JSON.stringify(item)"
              :label="item.colDesc"
              :value="item.colDesc"
            />
          </el-select>中，找到<el-select v-model="activeRow.dealKey" size="mini" class="select">
            <el-option
              v-for="item in relationData.desc.colDesc"
              :key="JSON.stringify(item)"
              :label="item"
              :value="item"
            />
          </el-select>的
          <el-cascader
            ref="cascader"
            v-model="activeRow.method"
            size="mini"
            class="select"
            :options="methodList"
            :props="{ value: 'name', label: 'name' }"
            :show-all-levels="false"
            @input="changeMethod"
          />
          的值
        </el-form-item>
        <el-form-item v-if="currentDealOptSchema&&currentDealOptSchema.length" class="layout">
          <DealOptDeep v-model="activeRow.dealOpt" :options="currentDealOptSchema" />
        </el-form-item>
      </el-form>
      <div v-else style="text-align: center;">没有找到对应的报表</div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveStr"> 确定 </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { keysMap } from '@/components/DataChart/analyze.js'
import DealOptDeep from './DealOptDeep.vue'
export default {
  name: 'TinymceEdit',
  components: {DealOptDeep},
  props: {
    value: {
      type: String,
      default: ''
    },
    relationData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      reg: /#\*(.*?)\*#/g,
      // Define the regular expression to match data-value attribute
      regex: /data-value\s*=\s*["'](.*?)["']/,
      regSpan: /<span\s+[^>]*class\s*=\s*["']\s*analysis-results\s*["'][^>]*>(.*?)<\/span>/g,
      activeRow: {},
      dialogVisible: false,
      methodList: [],
      currentDealOptSchema: null,
      dealValue: '',
      initOption: {
        toolbar: '| insertCustomContent',
        setup: editor => {
          // 添加自定义按钮, 用于插入自定义内容
          editor.ui.registry.addButton('insertCustomContent', {
            text: '插入自定义内容',
            // icon: 'insert-custom-content',
            tooptip: '插入自定义内容',
            onAction: () => {
              // 判断有没有 span data-value 属性 包含 为请选择的元素
              let newObj = {
                source: '',
                dealKey: '',
                method: [],
                dealOpt: {},
                custom_id: this.$nanoid()
              }
              let insertContentStr = this.defaultSpanTem(newObj)
              // 在光标位置插入内容
              // 内容为空时也需要插入

              // editor.insertContent(insertContentStr)
              // 使用 ExecCommand 方法在当前光标位置插入内容
              editor.execCommand('mceInsertContent', false, insertContentStr)
              // this.dealValue = editor.getContent()
            }
          })
        }
      }
    }
  },
  watch: {
    value: {
      handler(newVal) {
        this.dealValue = newVal.replace(this.reg, (match, p1) => {
          let jsonP1 = JSON.parse(p1)
          jsonP1.custom_id = jsonP1.custom_id || this.$nanoid()
          return this.defaultSpanTem(jsonP1)
        })
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.dealMethods()
    // 添加 双击事件
    this.$nextTick(() => {
      setTimeout(() => {
        let iframe = document.querySelector('.tinymce-edit + .tox-tinymce iframe')
        let dom = iframe.contentDocument || iframe.contentWindow.document
        // 使用事件委托,给每个span添加双击事件
        dom.addEventListener('dblclick', e => {
          if (e.target.className === 'analysis-results') {
            this.activeRow = JSON.parse(decodeURI(e.target.dataset.value))
            this.dialogVisible = true
          }
        })
      }, 1000)
    })
  },
  methods: {
    handlerDialogOpen() {
      if (Object.keys(this.relationData).length) {
        this.$nextTick(() => {
          this.changeMethod()
        })
      }
    },
    defaultSpanTem(json) {
      let showLabel = '占位项'
      // 判断 json.method 是否是数组, 如果是数组, 则取数组最后一项
      if (Object.prototype.toString.call(json.method) === '[object Array]') {
        showLabel = json.method[json.method.length - 1] || '占位项'
      }
      return `<span
          id="${json.custom_id}"
          contenteditable="false"
          style="
            display: inline-block;
            background-color: #f5f5f5;
            border: 1px solid #ebebeb;
            border-radius: 4px;
            margin: 0 2px;
            max-width: 100px;
            vertical-align: top;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;
          "
          class="analysis-results"
          title="${json.source}-${json.dealKey}-${json.method}"
          data-value='${encodeURI(JSON.stringify(json))}'>${showLabel}</span>`
    },
    dealMethods() {
      // 递归处理 keysMap, 将其 key 组成树形结构数据
      function dealKeysMap(keysMap) {
        let arr = []
        for (let key in keysMap) {
          let obj = { name: key }
          if (keysMap[key]?.dealOptSchema) {
            obj['dealOptSchema'] = keysMap[key].dealOptSchema
          }
          // 如果是对象,且keysMap[key]中没有 handler 处理函数, 则递归处理, 有dealOptSchema属性的，就添加到当前对象中
          if (!keysMap[key]?.handler) {
            // do something
            obj.children = dealKeysMap(keysMap[key])
          }
          arr.push(obj)
        }
        return arr
      }
      this.methodList = dealKeysMap(keysMap)
    },
    // 弹窗保存
    saveStr() {
      let deal = this.dealValue.replace(this.regSpan, matchSpan => {
        const matches = this.regex.exec(matchSpan)
        const dataValueAttribute = decodeURI(matches && matches[1])
        let jsonP1 = JSON.parse(dataValueAttribute)
        if (jsonP1.custom_id === this.activeRow.custom_id) {
          jsonP1 = this.activeRow
        }
        return this.defaultSpanTem(jsonP1)
      })
      this.dealValue = deal
      this.$nextTick(() => {
        this.dialogVisible = false
      })
    },
    // 选择处理方法
    changeMethod() {
      let { dealOptSchema } = this.$refs.cascader.getCheckedNodes()[0]?.data || {}
      if (dealOptSchema) {
        this.currentDealOptSchema = dealOptSchema
      } else {
        this.currentDealOptSchema = null
      }
    },
    // 获取源数据
    getSourceValue() {
      let str = this.dealValue.replace(this.regSpan, matchSpan => {
        const matches = this.regex.exec(matchSpan)
        const dataValueAttribute = decodeURI(matches && matches[1])
        return `#*${dataValueAttribute}*#`
      })
      return str
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.tinymce-edit + :deep(.tox-tinymce) {
  // 选中 class 为 analysis-results-* 的元素
  .tox-edit-area__iframe {
    [class^='analysis-results-'] {
      background-color: #f5f5f5;
      border: 1px solid #ebebeb;
      border-radius: 4px;
      padding: 2px 4px;
      margin: 0 2px;
    }
  }
}
.el-dialog__body {
  .layout {
    display: flex;
    align-items: center;
    .select {
      width: 120px;
      margin: 0 10px;
    }
  }
}
</style>
