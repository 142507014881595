<template>
  <div class="create-or-edit">
    <div style="text-align: right;">
      <el-button type="text" icon="el-icon-back" @click="$router.back()">返回上一页</el-button>
    </div>
    <div v-loading="reportModelLoading" class="context">
      <el-steps
        v-if="!$route.query.reportId"
        style="margin-bottom: 10px;"
        :active="active"
        align-center
        finish-status="success"
      >
        <el-step title="第一步：选择报表" />
        <el-step title="第二步：筛选数据源" />
        <el-step title="第三步：查看报告并下载" />
      </el-steps>
      <CreateOrEditStep1 v-if="active === 0" ref="create-or-edit-step0" v-model="reportForm" />
      <CreateOrEditStep2 v-if="active === 1" ref="create-or-edit-step1" v-model="reportForm" />
      <CreateOrEditStep3
        v-if="active === 2"
        ref="create-or-edit-step2"
        :show-type-text="showTypeText"
        :report-form="reportForm"
      />
    </div>
    <footer-bottom-box style="text-align: center;">
      <el-button
        v-if="!$route.query.reportId || $route.query.reportModelId"
        size="small"
        :disabled="active === 0"
        @click="preStep"
      >
        上一步
      </el-button>
      <el-button v-if="active !== 2" size="small" @click="onSubmit">下一步</el-button>
      <template v-if="active === 2">
        <el-button size="small" :loading="loading" @click="onSaveReport">保存报告</el-button>
        <el-button size="small" @click="outPutPdfFn">下载pdf</el-button>
      </template>
    </footer-bottom-box>
  </div>
</template>

<script>
import CreateOrEditStep1 from './components/CreateOrEditStep1.vue'
import CreateOrEditStep2 from './components/CreateOrEditStep2.vue'
import CreateOrEditStep3 from './components/CreateOrEditStep3.vue'
import { showTypeText } from '../utils.js'
import { getPdf } from './components/reportDeal.js'

import { reportSaveOrUpdate, detail } from '@/apis/reportService.js'
export default {
  name: 'ReportCreateOrEdit',
  components: { CreateOrEditStep1, CreateOrEditStep2, CreateOrEditStep3 },
  data() {
    return {
      active: 0,
      loading: false,
      reportModelLoading: false,
      reportForm: {
        reportModelName: '',
        reportAndModelDetailList: [],
        reportAndModelDetailVOList: [],
        queryInfos: [],
        placeholderWhereInfos: []
      },
      reportModelDetails: {},
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  computed: {
    smartModelIdList() {
      if (this.reportModelDetails?.reportAndModelDetailVOList) {
        return this.reportModelDetails?.reportAndModelDetailVOList
          .filter(item => item.placeholderType === 3)
          .map(item => {
            return item.smartModelId
          })
      }
      return []
    },

    actStepDom() {
      return this.$refs[`create-or-edit-step${this.active}`]
    },
    showTypeText
  },
  mounted() {
    this.active = this.$route.query.reportId ? 2 : 0
    const { reportModelId } = this.$route.query
    if (reportModelId) {
      this.getDetails(reportModelId)
    }
  },
  methods: {
    // 查询报告模板详情
    async getDetails(reportModelId) {
      try {
        this.reportModelLoading = true
        const res = await detail({ reportModelId })
        const { reportAndModelDetailVOList = [] } = res
        this.reportForm.reportAndModelDetailVOList = reportAndModelDetailVOList.map(item => {
          if (item.placeholderType === 5) {
            this.reportForm.reportModelName = item.placeholder
          }
          return item
        })
        this.actStepDom.getClassifyList()
        this.reportModelLoading = false
      } catch (error) {
        console.log(error)
      }
    },
    preStep() {
      this.active--
    },
    onSubmit() {
      this.actStepDom.submit().then(res => {
        this.reportForm = {
          ...this.reportForm,
          ...res
        }
        this.nextStep()
      })
    },

    // 生成报告
    outPutPdfFn() {
      this.actStepDom.activeItem = {}
      this.$nextTick(async() => {
        // 传入title和dom标签，此处是 #content
        const params = await this.actStepDom.submit()
        // 异步函数，导出成功后处理交互
        getPdf(params.reportModelName, '.page-wrap')
          .then(() => {
            // 自定义等待动画关闭
            this.$message({
              type: 'success',
              message: '导出成功'
            })
            this.detailSHow = false
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '导出失败，请重试'
            })
          })
      })
    },
    isSplit(nodes, index, pageHeight) {
      // 计算当前这块dom是否跨越了a4大小，以此分割
      if (nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight && nodes[index + 1] && nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight) {
        return true
      }
      return false
    },
    nextStep() {
      this.active++
    },
    async onSaveReport() {
      try {
        const params = await this.actStepDom.submit()
        params.reportModelId = this.$route.query.reportModelId
        this.loading = true
        const res = await reportSaveOrUpdate(params)
        if (res) {
          this.$message.success('保存成功')
          this.$router.back()
        }
        this.loading = false
      } catch (error) {
        this.$message.error(error.message)
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.create-or-edit {
  display: flex;
  flex-direction: column;
  .context {
    flex: 1;
  }
}
</style>
