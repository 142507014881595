<template>
  <el-container style="height: calc(100vh - 40px); border: 1px solid #eee;">
    <el-aside width="200px" style="background-color: rgb(238, 241, 246);">
      <div
        v-for="item in tableData"
        :key="item.id"
        class="droppable-element"
        draggable="true"
        unselectable="on"
        @drag="drag"
        @dragend="dragend"
      >
        Droppable Element (Drag me!){{ item.i }}
      </div>
    </el-aside>
    <el-container id="content" style="height: calc(100vh - 40px);">
      <grid-layout
        ref="gridlayout"
        :layout.sync="layout"
        :col-num="12"
        :row-height="30"
        :is-draggable="true"
        :is-resizable="true"
        :vertical-compact="true"
        :use-css-transforms="true"
      >
        <div
          v-if="Object.keys(activeItem).length && rightMenu.show"
          class="right-menu"
          :style="rightMenu.style"
          @click="rightMenu.show = false"
        >
          <slot name="rightMenu" />
        </div>
        <grid-item
          v-for="item in layout"
          :key="item.i"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
        >
          <div
            class="item-wrap"
            :class="{ html: item.placeholderType === 2 }"
            :data-id="item.id"
            @click.right.prevent.stop="onActive"
          >
            <slot name="dargItem" :item="item" />
          </div>
        </grid-item>
      </grid-layout>
    </el-container>
  </el-container>
</template>

<script>
import { GridLayout, GridItem } from 'vue-grid-layout'
import {showTypeText} from '../utils.js'
import { getSmartReportFormList } from '@/apis/reportService.js'
const mouseXY = { x: null, y: null }
const DragPos = { x: null, y: null, w: 1, h: 1, i: null }
export default {
  name: 'Detail',
  components: {
    GridLayout,
    GridItem
  },
  props: {
    value: {
      type: Array,
      default: Array
    },
    visible: {
      type: Boolean,
      default: false
    },
    activeItemProp: {
      type: Object,
      default: Object
    },
    reportForm: {
      type: Object,
      default: Object
    }
  },
  data() {
    return {
      tableData: [],
      layout: []
    }
  },
  computed: {
    formatList: {
      get() {
        return this.value
      },
      set(val) {
        console.log('set', val)
        this.$emit('input', val)
      }
    },
    drawerEditVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    activeItem: {
      get() {
        return this.activeItemProp
      },
      set(val) {
        this.$emit('update:active-item-prop', val)
      }
    },
    showTypeText
  },
  mounted() {
    document.addEventListener(
      'dragover',
      function(e) {
        mouseXY.x = e.clientX
        mouseXY.y = e.clientY
      },
      false
    )

    this.getDataList()
  },
  methods: {
    async getDataList() {
      if (this.loading) return
      this.loading = true
      try {
        const { data = [] } = await getSmartReportFormList({pageSize: 99999})
        this.tableData = data
        this.loading = false
        this.toggleSelection()
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    drag() {
      const parentRect = document.getElementById('content').getBoundingClientRect()
      let mouseInGrid = false
      if (mouseXY.x > parentRect.left && mouseXY.x < parentRect.right && mouseXY.y > parentRect.top && mouseXY.y < parentRect.bottom) {
        mouseInGrid = true
      }
      if (mouseInGrid === true && this.layout.findIndex(item => item.i === 'drop') === -1) {
        this.layout.push({
          x: (this.layout.length * 2) % 24,
          y: this.layout.length + 24, // puts it at the bottom
          w: 1,
          h: 1,
          i: 'drop'
        })
      }
      const index = this.layout.findIndex(item => item.i === 'drop')
      if (index !== -1) {
        try {
          this.$refs.gridlayout.$children[this.layout.length].$refs.item.style.display = 'none'
        } catch {
          console.log(12)
        }
        const el = this.$refs.gridlayout.$children[index]
        el.dragging = { top: mouseXY.y - parentRect.top, left: mouseXY.x - parentRect.left }
        const new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left)
        if (mouseInGrid === true) {
          this.$refs.gridlayout.dragEvent('dragstart', 'drop', new_pos.x, new_pos.y, 1, 1)
          DragPos.i = String(index)
          DragPos.x = this.layout[index].x
          DragPos.y = this.layout[index].y
        }
        if (mouseInGrid === false) {
          this.$refs.gridlayout.dragEvent('dragend', 'drop', new_pos.x, new_pos.y, 1, 1)
          this.layout = this.layout.filter(obj => obj.i !== 'drop')
        }
      }
    },
    dragend() {
      const parentRect = document.getElementById('content').getBoundingClientRect()
      let mouseInGrid = false
      if (mouseXY.x > parentRect.left && mouseXY.x < parentRect.right && mouseXY.y > parentRect.top && mouseXY.y < parentRect.bottom) {
        mouseInGrid = true
      }
      if (mouseInGrid === true) {
        // alert(`Dropped element props:\n${JSON.stringify(DragPos, ['x', 'y', 'w', 'h'], 2)}`)
        this.$refs.gridlayout.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, 1, 1)
        this.layout = this.layout.filter(obj => obj.i !== 'drop')
        // UNCOMMENT below if you want to add a grid-item
        this.layout.push({
          x: DragPos.x,
          y: DragPos.y,
          w: 1,
          h: 1,
          i: DragPos.i
        })
        this.$refs.gridLayout.dragEvent('dragend', DragPos.i, DragPos.x, DragPos.y, 1, 1)
        try {
          this.$refs.gridLayout.$children[this.layout.length].$refs.item.style.display = 'block'
        } catch {
          console.log(123)
        }
      }
    }
  }
}
</script>

<style scoped>
.droppable-element {
  width: 150px;
  text-align: center;
  background: #fdd;
  border: 1px solid black;
  margin: 10px 0;
  padding: 10px;
}
.vue-grid-layout {
  width: 100%;
  background: #eee;
}
.vue-grid-item:not(.vue-grid-placeholder) {
  background: #ccc;
  border: 1px solid black;
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}
.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
</style>
