<template>
  <!--
  时间替换，如果一选了主占位项，则替换，如果没有选主占位项，则添加主占位项
 -->
  <div class="page-bg">
    <el-form inline size="small">
      <el-form-item>
        <OrgList
          size="small"
          :org-list="reportForm.queryInfos?.map((item) => item.queryObjectId)"
          placeholder="选择组织"
          class="mgn-b10"
          @choose-list="$set(reportForm, 'queryInfos', $event)"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="mainPlaceholderInfo.mainTimeType"
          clearable
          placeholder="选择时间"
          @change="mainPlaceholderInfo.timeMemberIdValues = [];mainPlaceholderInfo.dynamicTimeType = ''"
        >
          <el-option label="静态时间" :value="2" />
          <el-option label="动态时间" :value="1" />
        </el-select>
      </el-form-item>
      <el-form-item v-if="mainPlaceholderInfo.mainTimeType === 2">
        <el-date-picker
          :value="mainPlaceholderInfo.timeMemberIdValues.length ? [mainPlaceholderInfo.timeMemberIdValues[0].value, mainPlaceholderInfo.timeMemberIdValues[1].value] : []"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @input="handleTime($event)"
        />
      </el-form-item>
      <el-form-item v-if="mainPlaceholderInfo.mainTimeType === 1">
        <el-radio-group v-model="mainPlaceholderInfo.dynamicTimeType" @input="handleDynameicTimeType($event)">
          <el-radio :label="1" border>近一周</el-radio>
          <el-radio :label="2" border>近一个月</el-radio>
          <el-radio :label="3" border>近三个月</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button @click="loadModelData(list)">确定</el-button>
      </el-form-item>
    </el-form>
    <div class="time-tips">备注：静态时间筛选出来的数据报表，时间固定。动态时间筛选出来的数据报表，是实时变化的。</div>
    <DargLayout
      ref="dary-layout"
      v-model="list"
      class="wrap-page"
      :report-form="reportForm"
      :visible.sync="drawerEditVisible"
      :active-item-prop.sync="activeItem"
    >
      <!-- 右键菜单 -->
      <template #rightMenu>
        <div class="item" @click="drawerEditVisible = true">编辑</div>
        <div v-if="activeItem.placeholderType !== 4" class="item" @click="onDel(activeItem)">删除</div>
        <div v-if="!isReportTitle" class="item" @click="insertReportTitle">插入报告标题</div>
        <div v-if="activeItem.placeholderType === 3" class="item" @click="insert('title')">插入标题</div>
        <div v-if="activeItem.placeholderType === 3" class="item" @click="insert('analysis')">插入分析</div>
        <!-- <el-button @click="clear">clear</el-button> -->
      </template>
      <!-- darg-item -->
      <template #dargItem="{ item }">
        <!-- <p v-if="item.placeholderType === 4" style="text-align: right">报告更新时间：{{ item.placeholder }}</p> -->
        <h1 v-if="item.placeholderType === 5" style="text-align: center;">{{ item.placeholder }}</h1>
        <draggable-item-html v-if="item.placeholderType === 1 || item.placeholderType === 2 || item.placeholderType === 4" :relation-data="relationData(item)" :item="item" />
        <DataChart
          v-if="item.placeholderType === 3"
          :ref="`data-chart-${item.i}`"
          v-loading="item.loading"
          class="data-chart"
          :data="item.data"
          :show-type-name="item.showType || 'table'"
          :form-chart-option="item.formChartOption"
          :js-code-snippet="item.jsCodeSnippet"
        />
      </template>

      <!-- 弹窗 -->
      <template v-if="drawerEditVisible" #drawer="scoped">
        <div class="toop-tip">布局项总占位宽度为24,高度每份15像素</div>
        <el-form
          ref="activeItemForm"
          label-suffix=":"
          size="mini"
          :rules="activeItemRules"
          :model="activeItem"
        >
          <div style="display: grid; gap: 10px; grid-template-columns: 150px 150px;">
            <el-form-item label="宽" prop="w">
              <el-input-number
                v-model="activeItem.w"
                style="width: 80px;"
                :step="1"
                :min="1"
                step-strictly
                controls-position="right"
              />
            </el-form-item>
            <el-form-item label="高" prop="h">
              <el-input-number
                v-model="activeItem.h"
                style="width: 80px;"
                :step="1"
                :min="1"
                step-strictly
                controls-position="right"
              />
            </el-form-item>
          </div>
          <!-- 富文本 -->
          <tinymce-edit
            v-if="activeItem.placeholderType === 2 || activeItem.placeholderType === 1"
            ref="tinymceEdit"
            :value="activeItem.placeholder"
            :relation-data="relationData(activeItem)"
            classify="115"
          />
          <el-form-item v-if="activeItem.placeholderType === 4 || activeItem.placeholderType === 5" label="标题" prop="placeholder">
            <el-input v-model.trim="activeItem.placeholder" placeholder="可修改报告标题" />
          </el-form-item>
          <!-- 报表 -->
          <template v-if="activeItem.placeholderType === 3">
            <el-form-item label="报表标题" prop="placeholder">
              <el-input v-model.trim="activeItem.placeholder" clearable placeholder="请输入报表标题" />
            </el-form-item>
            <el-form-item label="组织">
              <OrgList
                v-if="scoped.visible"
                :org-list="activeItem.reportConditionItem.queryInfos.map((item) => item.queryObjectId)"
                style="width: 100%;"
                @choose-list="$set(activeItem.reportConditionItem, 'queryInfos', $event)"
              />
            </el-form-item>
            <el-form-item v-if="activeItem.reportConditionItem.placeList" label="数据筛选">
              <el-col class="mgn-b10">
                <el-button @click="addPlace">添 加</el-button>
              </el-col>
              <el-col v-for="v in activeItem.reportConditionItem.placeholderWhereInfos" :key="v.selfId" class="mgn-b10">
                <el-col :span="5">
                  <el-select
                    :value="v"
                    style="width: 100%;"
                    value-key="colDesc"
                    placeholder="请选择"
                    @change="(e) => selectPlaceItem(e, v)"
                  >
                    <el-option
                      v-for="item in activeItem.reportConditionItem.placeList"
                      :key="item.colDesc"
                      :label="item.colDesc"
                      :value="item"
                    />
                  </el-select>
                </el-col>
                <el-col :span="5">
                  <el-select
                    v-model="v.sqlMemberId"
                    style="width: 100%;"
                    value-key="sqlMemberId"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in dateRange"
                      :key="item.sqlMemberId"
                      :label="item.showText"
                      :value="item.sqlMemberId"
                    />
                  </el-select>
                </el-col>
                <el-col v-if="v.dataType === 3" :span="9">
                  <el-date-picker
                    v-model="v.value"
                    style="width: 100%;"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    placeholder="选择日期"
                  />
                </el-col>
                <el-col v-else-if="v.dataType === 1" :span="9">
                  <el-input v-model.trim.number="v.value" placeholder="请输入" />
                </el-col>
                <el-col v-else :span="9">
                  <el-input v-model.trim="v.value" :placeholder="v.colRemark" />
                </el-col>
                <el-col :span="5">
                  <el-button @click="deletePlace(v)">删 除</el-button>
                </el-col>
              </el-col>
            </el-form-item>
          </template>
        </el-form>
      </template>
    </DargLayout>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'
import DraggableItemHtml from '../../components/draggable-item-html.vue'
import DargLayout from '../../components/darg-layout.vue'
import { queryModelData, queryPlaceholder, getRequestSqlMemberList } from '@/apis/reportService.js'
import { cloneDeep } from 'lodash-es'
import TinymceEdit from './TinymceEdit'

export default {
  name: 'CreateOrEditStep3',
  components: {
    DraggableItemHtml,
    DargLayout,
    TinymceEdit
  },
  props: {
    reportForm: {
      type: Object,
      default: Object
    },
    showTypeText: Function
  },
  data() {
    return {
      drawerEditVisible: false,
      activeItem: {
        reportConditionItem: {}
      },
      activeItemRules: {
        w: [{ required: true, message: '请输入报表宽度', trigger: 'blur' }],
        h: [{ required: true, message: '请输入报表高度', trigger: 'blur' }],
        placeholder: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        'reportConditionItem.queryInfos': [{ required: true, type: 'array', message: '请选择组织', trigger: 'blur' }]
      },
      list: [],
      dateRange: [],
      shortcuts: [],
      mainPlaceholderInfo: {
        mainTimeType: null,
        timeMemberIdValues: [],
        dynamicTimeType: null
      }
    }
  },
  computed: {
    infoTitle() {
      return {
        x: 0,
        y: 0,
        w: 24,
        h: 4,
        placeholderType: 5,
        placeholder: this.reportForm.reportModelName || '趣学术报告模板',
        i: nanoid()
      }
    },
    isReportTitle() {
      return this.list.some(item => item.placeholderType === 5)
    }
  },
  mounted() {
    this.init()
    this.getAggregation()
  },
  methods: {
    init() {
      const { reportId } = this.$route.query
      if (reportId) {
        // 查看报告详情
        this.getReportDetails(reportId)
      } else {
        // 自定义创建报告
        this.insertReportTitle()
        this.handleChooseManually(this.reportForm.reportAndModelDetailList)
      }
    },
    // 聚合条件
    async getAggregation() {
      try {
        const res = await getRequestSqlMemberList({ type: 1 })
        this.dateRange = res
      } catch (error) {
        console.log(error)
      }
    },
    // 查询报告详情
    async getReportDetails(reportId) {
      try {
        const res = await this.$axios.get(this.$API.reportDetail, { params: { reportId } })
        // 判断是否有占位条件
        if (res.mainPlaceholderInfo && res.mainPlaceholderInfo.mainTimeType === 1) {
          res.mainPlaceholderInfo.timeMemberIdValues = [{
            value: this.getDynamicTime(res.mainPlaceholderInfo.dynamicTimeType),
            sqlMemberId: this.getSqlMemberIdValue('大于等于')
          }, {
            value: this.getDynamicTime(0),
            sqlMemberId: this.getSqlMemberIdValue('小于等于')
          }]
        }
        this.mainPlaceholderInfo = res.mainPlaceholderInfo || this.mainPlaceholderInfo

        res.reportAndModelDetailVOList = res.reportAndModelDetailVOList.sort((a, b) => {
          const {y: ay} = JSON.parse(a.placeholderStyle)
          const {y: by} = JSON.parse(b.placeholderStyle)
          return ay - by
        })
        // 循环添加到 list 中, 先渲染页面
        for (let index = 0; res.reportAndModelDetailVOList.length > index; index++) {
          let item = res.reportAndModelDetailVOList[index]
          if (item.placeholderType === 3 && item.reportConditionVO) {
            item.reportConditionItem = item.reportConditionVO
            delete item.reportConditionVO
          }
          this.updateChartItemData(item, index)
        }

        // 添加报表数据
        await this.loadModelData(res.reportAndModelDetailVOList)
      } catch (error) {
        console.log(error)
      }
    },
    // 加载报表数据
    async loadModelData(modelList) {
      for (let index = 0; modelList.length > index; index++) {
        let item = modelList[index]
        if (item.placeholderType === 3) {
          // 判断是报表时，添加报表数据
          if (item.reportConditionItem) {
            const res = await queryPlaceholder({ smartModelId: item.smartModelId })
            item.reportConditionItem.placeList = res || []
            item.reportConditionItem.placeholderWhereInfos = this.replaceIsJoinColPlace(item.reportConditionItem.placeholderWhereInfos, item.reportConditionItem.placeList)

            item.reportConditionItem.queryInfos = this.reportForm.queryInfos
          }
          let params = cloneDeep(item)
          delete params?.reportConditionItem?.placeList
          await this.updateChartItemServiesData(params)
        }
      }
    },
    // 获取动态时间
    getDynamicTime(type) {
      let time = 0
      switch (type) {
        case 1:
          time = 7
          break
        case 2:
          time = 30
          break
        case 3:
          time = 90
          break
        case 0:
          time = 0
          break
      }
      const data = new Date().getTime()
      return this.$moment(data - 3600 * 1000 * 24 * time).format('YYYY-MM-DD HH:mm:ss')
    },
    // 处理手动选择的报表
    async handleChooseManually(arr, type) {
      for (let index = 0; arr.length > index; index++) {
        const item = arr[index]
        const obj = {
          placeholderType: 3,
          placeholder: item.modelName,
          smartModelId: item.smartModelId,
          reportConditionItem: cloneDeep(this.reportForm)
        }
        delete obj.reportConditionItem.reportAndModelDetailList
        if (item.smartModelId) {
          const res = await queryPlaceholder({ smartModelId: item.smartModelId })
          obj.reportConditionItem.placeList = res
        }
        this.updateChartItemData(obj, index, type)
        this.updateChartItemServiesData(obj)
      }
    },
    // 报告时间改变时更新报表数据
    handleChangeTime(arr, y = 1) {
      for (let index = 0; arr.length > index; index++) {
        let item = arr[index]
        if (item.smartModelId && this.mainPlaceholderInfo.timeMemberIdValues.length && item.reportConditionItem.placeList) {
          item.reportConditionItem.placeholderWhereInfos = this.replaceIsJoinColPlace(item.reportConditionItem.placeholderWhereInfos, item.reportConditionItem.placeList)
          const obj = {
            placeholderType: 3,
            placeholder: item.modelName,
            smartModelId: item.smartModelId,
            y,
            reportConditionItem: item.reportConditionItem
          }
          this.updateChartItemData(obj, index, 'update')
          this.updateChartItemServiesData(obj)
        }
      }
    },
    // 查询报表数据
    async getChartData({ smartModelId: modelId, reportConditionItem }) {
      try {
        return await queryModelData({
          ...reportConditionItem,
          modelId,
          containsIsDel: true
        })
      } catch (error) {
        console.log(error)
      }
    },
    // 更新单个报表样式数据
    updateChartItemData(chartItem, index, type = 'add') {
      const { x = (index % 2) * 12, y = 0, w = 12, h = 18, placeholderType, placeholder, smartModelId, reportAndModelDetailId = nanoid(), reportConditionItem, showType } = chartItem
      const placeholderStyle = JSON.parse(chartItem.placeholderStyle || '{}')
      const obj = {
        x,
        y,
        w,
        h,
        placeholderType,
        placeholder,
        smartModelId,
        i: reportAndModelDetailId,
        showType: this.showTypeText(showType)?.value,
        reportConditionItem,
        ...placeholderStyle
      }
      type === 'add' && this.list.push(obj)
      if (obj.placeholderType === 3) {
        const { queryInfos } = obj.reportConditionItem || {}
        if (!queryInfos?.length) {
          this.activeItem = obj
          // this.$refs['dary-layout'].drawerEditVisible = true
        } else {
          obj.data = { modelName: obj.placeholder }
        }
        obj.loading = false
      }
    },
    // 更新单个报表服务端数据
    async updateChartItemServiesData(row) {
      let serviesData = null
      try {
        row.loading = true
        this.list = this.list.map(item => {
          if (item.placeholderType === row.placeholderType && item.smartModelId === row.smartModelId) {
            item.loading = true
          }
          return item
        })
        serviesData = await this.getChartData(row)
      } finally {
        this.list = this.list.map(item => {
          if (item.placeholderType === row.placeholderType && item.smartModelId === row.smartModelId) {
            item.data = serviesData
            item.data.modelName = item.placeholder ? item.placeholder : serviesData.desc.modelName
            item.placeholder = item.data.modelName
            item.showType = this.showTypeText(serviesData.desc.showDesc.showType)?.value
            item.formChartOption = typeof JSON.parse(serviesData.desc.showDesc.chartOptions || '{}') === 'string' ? {} : JSON.parse(serviesData.desc.showDesc.chartOptions || '{}')
            item.jsCodeSnippet = serviesData.desc.showDesc.jsCodeSnippet
          }
          item.loading = false
          return item
        })
      }
    },
    addPlace() {
      this.activeItem.reportConditionItem.placeholderWhereInfos.unshift({
        selfId: this.$nanoid()
      })
    },
    deletePlace(row) {
      this.activeItem.reportConditionItem.placeholderWhereInfos = this.activeItem.reportConditionItem.placeholderWhereInfos.filter(j => JSON.stringify(j) !== JSON.stringify(row))
    },
    // 设置占位
    selectPlaceItem(e, v) {
      this.activeItem.reportConditionItem.placeholderWhereInfos = this.activeItem.reportConditionItem.placeholderWhereInfos.map(i => {
        if (i.selfId === v.selfId) {
          i = { ...i, ...e }
        }
        return i
      })
    },
    // 替换主占位时间
    replaceIsJoinColPlace(placeholderWhereInfos, placeList) {
      if (!this.mainPlaceholderInfo.mainTimeType) return placeholderWhereInfos
      // placeholderWhereInfos 如果有主占位项, 则替换主占位项，如果没有主占位项，则添加主占位项
      const { timeMemberIdValues } = this.mainPlaceholderInfo
      const isJoinColFlag = placeholderWhereInfos.some(item => item.isJoinCol)

      if (!isJoinColFlag) {
        const isJoinColRow = placeList?.find(item => item.isJoinCol)
        if (placeList.length === 0 || !isJoinColRow) {
          return placeholderWhereInfos
        }
        placeholderWhereInfos.push(
          {
            colDesc: isJoinColRow.colDesc,
            dataType: isJoinColRow.dataType,
            sqlMemberId: timeMemberIdValues[0]?.sqlMemberId,
            value: timeMemberIdValues[0]?.value,
            isJoinCol: true,
            selfId: this.$nanoid()
          },
          {
            colDesc: isJoinColRow.colDesc,
            dataType: isJoinColRow.dataType,
            sqlMemberId: timeMemberIdValues[1]?.sqlMemberId,
            value: timeMemberIdValues[1]?.value,
            isJoinCol: true,
            selfId: this.$nanoid()
          }
        )
      } else {
        placeholderWhereInfos = placeholderWhereInfos.map(item => {
          if (item.isJoinCol) {
            if (item.sqlMemberId === timeMemberIdValues[0]?.sqlMemberId) {
              return {
                colDesc: item.colDesc,
                dataType: item.dataType,
                sqlMemberId: timeMemberIdValues[0]?.sqlMemberId,
                value: timeMemberIdValues[0]?.value,
                isJoinCol: true,
                selfId: this.$nanoid()
              }
            } else if (item.sqlMemberId === timeMemberIdValues[1]?.sqlMemberId) {
              return {
                colDesc: item.colDesc,
                dataType: item.dataType,
                sqlMemberId: timeMemberIdValues[1]?.sqlMemberId,
                value: timeMemberIdValues[1]?.value,
                isJoinCol: true,
                selfId: this.$nanoid()
              }
            }
          }
          return item
        }).filter(item => item.isJoinCol ? item.value : true)
      }
      // if (timeMemberIdValues.length === 0) {
      //   placeholderWhereInfos = placeholderWhereInfos.filter(item => !item.isJoinCol)
      // }
      return placeholderWhereInfos
    },
    // 验证表单
    validate() {
      return new Promise((reslove, reject) => {
        this.$refs.activeItemForm.validate(valid => {
          if (valid) {
            reslove()
          } else {
            console.log('error submit!!')
            reject()
            return false
          }
        })
      })
    },
    // 子组件需调用的方法：更新报表
    async updateChartItem(row, type) {
      if (type === 'add') {
        console.log('弹窗')
      } else {
        this.updateChartItemData(row, 0, type)
        if (row.placeholderType === 3) {
          await this.updateChartItemServiesData(row)
        }
      }
    },
    // 插入报告标题
    insertReportTitle() {
      this.list.unshift(this.infoTitle)
    },
    // 插入分析
    insert(type) {
      this.list.some((item, index) => {
        if (item.i === this.activeItem.i) {
          const obj = {
            i: nanoid(),
            x: item.x,
            y: item.y,
            w: item.w,
            h: 3,
            placeholder: '请输入要插入的内容',
            smartModelId: this.activeItem.smartModelId
          }
          if (type === 'title') {
            obj.placeholderType = 1
            this.list.splice(index === 0 ? 0 : index - 1, 0, obj)
          } else {
            obj.placeholderType = 2
            this.list.splice(index + 1, 0, obj)
          }
          this.activeItem = cloneDeep(obj)
          return true
        }
        return false
      })
      this.drawerEditVisible = true
    },
    // 删除
    onDel(row) {
      this.$confirm('此操作将删除该项内容以及所关联的内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.list = this.list.filter(item => {
          if (row.placeholderType === 3) {
            return item.i !== row.i
          }
          if (row.placeholderType === item.placeholderType && row.smartModelId === item.smartModelId && row.i === item.i) {
            return false
          }
          return true
        })
        this.$message({
          type: 'success',
          message: '删除成功'
        })
      })
    },
    // 提交
    submit() {
      return new Promise((resolve, reject) => {
        const [row] = this.list.filter(item => {
          return item.placeholderType === 5
        })
        if (this.list.length === 0) {
          reject()
          return this.$message.error('报告不能为空')
        } else if (!row || !row.placeholder) {
          return this.$message.error('请添加报告名称')
        } else {
          const obj = {
            reportAndModelDetailList: [],
            reportRecordId: this.$route.query.reportId,
            reportModelName: this.reportModelName,
            mainPlaceholderInfo: this.mainPlaceholderInfo
          }
          if (obj.mainPlaceholderInfo.mainTimeType === 1) {
            obj.mainPlaceholderInfo.timeMemberIdValues = []
          }
          this.list.forEach(({ smartModelId, placeholder = '', reportConditionItem = {}, placeholderType = '', x, y, w, h }) => {
            const reportAndModelDetailItem = {
              placeholder,
              placeholderType,
              placeholderStyle: JSON.stringify({ x, y, w, h }),
              smartModelId
            }
            obj.reportModelName = placeholderType === 5 ? placeholder : obj.reportModelName
            if (placeholderType === 3) {
              reportAndModelDetailItem.reportConditionItem = reportConditionItem
              const { placeholderWhereInfos = [], placeList = [] } = reportAndModelDetailItem.reportConditionItem
              if (this.mainPlaceholderInfo && this.mainPlaceholderInfo.mainTimeType) {
                reportAndModelDetailItem.reportConditionItem.placeholderWhereInfos = this.replaceIsJoinColPlace(placeholderWhereInfos, placeList)
              }
              delete reportAndModelDetailItem.reportConditionItem.placeList
            }
            obj.reportAndModelDetailList.push(reportAndModelDetailItem)
          })
          resolve(obj)
        }
      })
    },
    // 修改时间
    handleTime(e) {
      if (!e) {
        this.mainPlaceholderInfo.timeMemberIdValues = []
      } else {
        this.mainPlaceholderInfo.timeMemberIdValues = e.map((item, index) => {
          return {
            sqlMemberId: index === 0 ? this.getSqlMemberIdValue('大于等于') : this.getSqlMemberIdValue('小于等于'),
            value: this.$moment(item).format('YYYY-MM-DD HH:mm:ss')
          }
        })
      }
    },
    // 修改动态时间
    handleDynameicTimeType(e) {
      this.mainPlaceholderInfo.timeMemberIdValues = [
        {
          sqlMemberId: this.getSqlMemberIdValue('大于等于'),
          value: this.getDynamicTime(e)
        },
        {
          sqlMemberId: this.getSqlMemberIdValue('小于等于'),
          value: this.getDynamicTime(0)
        }
      ]
    },
    // 获取 sqlMemberId 的值
    getSqlMemberIdValue(type) {
      let { sqlMemberId } = this.dateRange?.find(item => item.showText === type)
      return sqlMemberId
    },

    // 获取 源数据
    relationData(target) {
      const row = this.list.find(item => item.placeholderType === 3 && item.smartModelId === target?.smartModelId)

      return row?.data || {}
      // return this.$refs[`data-chart-${row.i}`]?.$children[0]?.chartOptions || {}
    }
  }
}
</script>

<style scoped lang="scss">

.time-tips {
  color: #c8c9ce;
  font-size: 14px;
  margin-bottom: 10px;
}
.wrap-page {
  --shadow-clip-rect: -3px -5px -7px -5px;

  border-radius: 4px;
  background-color: #fff;
  min-width: 1000px;
  width: 1470px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: #dedede 0 2px 6px 1px;
  font-size: 14px;
}
.page-wrap {
  transition: all 0.3s;
  position: relative;
  :deep(.vue-grid-item).vue-grid-placeholder {
    background-color: #5470c6;
  }
  .draggable-item {
    border-radius: 3px 0 3px 3px;
    :deep(.vue-resizable-handle) {
      display: none;
    }
    &.active {
      border-color: #cccc;
      cursor: move;
      z-index: 2;
      -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
      box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
      -webkit-transform: translate3d(0, -2px, 0);
      transform: translate3d(0, -2px, 0);
      :deep(.vue-resizable-handle) {
        display: block;
      }
    }
  }
}
.el-radio {
  margin-left: 0 !important;
  margin-right: 10px;
}
.drawer {
  :deep(.el-drawer__body) {
    padding: 10px;
    display: flex;
    flex-direction: column;
    .toop-tip {
      margin-bottom: 10px;
      font-size: 12px;
      color: red;
    }
  }
}
.drawer__footer {
  padding-top: 10px;
}
.right-menu {
  width: 200px;

  --bg1: hsla(0, 0%, 98%, 0.9);
  --bg1a: hsla(0, 0%, 97%, 0.96);
  --bg2: rgba(0, 0, 0, 0.08);

  -webkit-backdrop-filter: saturate(2) blur(20px);
  backdrop-filter: saturate(2) blur(20px);
  background: var(--bg1);
  border-radius: 8px;
  opacity: 1;
  position: absolute;
  transition: opacity 0.1s ease 0.3s;
  cursor: default;
  z-index: 3;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  .item {
    transition: all 0.3s;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    color: var(--txt-col);
    display: flex;
    font-size: 12px;
    font-weight: 500;
    height: 28px;
    margin: 6px;
    padding: 4px 6px;
    &:hover {
      background: var(--bg2);
    }
  }
}
</style>
