<template>
  <div class="create-or-edit-step2">
    <el-row>
      <el-form
        ref="form"
        label-suffix="："
        :model="form"
        :rules="formRules"
        label-position="right"
        label-width="100px"
      >
        <el-col :offset="8" :span="8">
          <el-form-item label="报告名称" prop="reportModelName">
            <el-input
              v-model="form.reportModelName"
              clearable
              style="width: 350px;"
              placeholder="请输入报表名称"
            />
          </el-form-item>
        </el-col>
        <el-col :offset="8" :span="8">
          <el-form-item label="选择组织" prop="queryInfos">
            <OrgList
              :org-list="form.queryInfos?.map(item => item.queryObjectId)"
              style="width: 350px;"
              @choose-list="chooseList"
            />
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'CreateOrEditStep2',
  props: {
    pickerOptions: Object,
    value: {
      type: Object,
      default: () => ({
        queryInfos: [],
        reportModelName: ''
      })
    }
  },
  data() {
    return {
      loading: false,
      formRules: {
        reportModelName: [{ required: true, message: '请输入报表名称', trigger: 'blur' }],
        queryInfos: [{required: true, type: 'array', message: '请选择组织', trigger: 'blur'}]
      }
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    chooseList(v) {
      this.$set(this.form, 'queryInfos', v)
    },
    submit() {
      return new Promise((reslove, reject) => {
        this.$refs['form'].validate(valid => {
          if (valid) {
            reslove(this.form)
          } else {
            reject()
            console.log('error submit!!')
            return false
          }
        })
      })
    }
  }
}
</script>
