<template>
  <div class="create-or-edit-step1">
    <el-tabs v-model="search.categoryId" type="card" @tab-click="handleClick">
      <el-tab-pane label="全部" name="" />
      <el-tab-pane
        v-for="item in classifyList"
        :key="item.categoryId"
        :label="item.categoryName"
        :name="item.categoryId"
      />
    </el-tabs>
    <!-- 表格 -->
    <el-table
      ref="table"
      v-loading="loading"
      :row-key="(row) => row && row.smartModelId"
      class="list-table"
      :data="tableData"
      border
      stripe
      highlight-current-row
      @select="handleSelect"
    >
      <el-table-column
        :selectable="selectable"
        class="selection"
        align="center"
        type="selection"
        :reserve-selection="true"
        width="55"
      />
      <el-table-column prop="modelName" label="图表名称" />
      <el-table-column prop="showType" label="报表占位项">
        <template slot-scope="scoped">
          <el-tag
            v-for="item in scoped.row.placeholderList"
            :key="item"
            type="warning"
            size="mini"
            style="margin-right: 5px;"
          >
            {{ item }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="refCount"
        width="80"
        label="引用次数"
      />
      <el-table-column
        prop="refCount"
        width="80"
        align="center"
        label="引用次数"
      />
      <el-table-column prop="createdAt" width="160" label="创建时间" />
      <el-table-column prop="updatedAt" width="160" label="更新时间" />
    </el-table>
    <div class="pagination-wrap">
      <el-pagination
        center
        :current-page="pagination.pageNo"
        :total="pagination.total"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.sizes"
        :layout="pagination.layout"
        :hide-on-single-page="false"
        background
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import { showTypeText } from '../../utils.js'
import { getListCategory, getSmartReportFormList } from '@/apis/reportService.js'
export default {
  name: 'CreateOrEditStep1',
  mixins: [paginationMixin],
  props: {
    value: {
      type: Object,
      default: Object
    },
    smartModelIdList: {
      type: Array,
      defautl: () => []
    }
  },
  data() {
    return {
      loading: false,
      addOrEditLoading: false,
      classifyList: [],
      tableData: [],
      search: {
        categoryId: ''
      }
    }
  },
  computed: {
    showTypeText,
    reportForm: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {
    this.getClassifyList()
  },
  methods: {
    async getDataList() {
      if (this.loading) return
      this.loading = true
      if (this.search.categoryId === '0') {
        this.search.categoryId = ''
      }
      const params = this.getParams(this.search)
      try {
        const { data = [], totalCount } = await getSmartReportFormList(params)
        this.tableData = data.map(item => {
          item.smartModelId = item.modelId
          item.placeholderType = 3
          return item
        })

        const arr = [...this.reportForm.reportAndModelDetailList, ...(this.reportForm.reportAndModelDetailVOList || [])]
        this.toggleSelection(arr, (item, row) => item.smartModelId === row.smartModelId)

        this.pagination.total = totalCount
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    async getClassifyList() {
      try {
        this.addOrEditLoading = true
        const res = await getListCategory({
          pageNo: 1,
          pageSize: 9999
        })
        this.classifyList = res
        this.getDataList()
        this.addOrEditLoading = false
      } catch (error) {
        this.addOrEditLoading = false
        console.log(error)
      }
    },
    toggleSelection(arr = [], func) {
      if (!arr?.length) return
      this.tableData.forEach(row => {
        arr.forEach(item => {
          if (func(item, row)) {
            this.$refs.table.toggleRowSelection(row)
          }
        })
      })
      // this.$refs.table.clearSelection()
    },
    handleSelect(selection, row) {
      const { reportAndModelDetailList } = this.reportForm
      const flag = reportAndModelDetailList.some(item => item.smartModelId === row.smartModelId)
      if (!flag) {
        this.reportForm.reportAndModelDetailList.push(row)
      } else {
        this.reportForm.reportAndModelDetailList = reportAndModelDetailList.filter(item => item.smartModelId !== row.smartModelId)
      }
    },
    selectable(row) {
      return !this.reportForm.reportAndModelDetailVOList?.some(item => item.smartModelId === row.smartModelId)
    },
    submit() {
      return new Promise((resolve, reject) => {
        const { reportAndModelDetailList = [], reportAndModelDetailVOList = [] } = this.reportForm
        if (reportAndModelDetailList?.length > 0 || reportAndModelDetailVOList.length > 0) {
          resolve(this.reportForm)
        } else {
          this.$message.error('请选择报表模板')
          reject()
        }
      })
    },
    handleClick() {
      this.pagination.pageNo = 1
      this.getDataList()
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.create-or-edit-step1 {
  .pagination-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  :deep(.el-table__header-wrapper) .el-checkbox {
    display: none;
  }
}
</style>
