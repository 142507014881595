// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'

export function getPdf(title, dom) {
  // 注册getPdf方法，传入两个参数，此处使用了promise处理导出后的操作
  /*
  title: 导出文件名
  dom: 需要导出dom的id
  */
  return new Promise((resolve, reject) => {
    html2Canvas(document.querySelector(dom), {
      useCORS: true, // 由于打印时，会访问dom上的一些图片等资源，解决跨域
      allowTaint: true, // 允许跨域
      scale: 1
    }).then(async canvas => {
      const contentWidth = canvas.width
      const contentHeight = canvas.height

      // A4纸的尺寸是210mm*297mm，也就是21.0cm*29.7cm，而1英寸=2.54cm，如果屏幕DPI分辨率为72像素/英寸，换算一下：相当于1cm可呈现 (72px/2.54cm) = 28.34px
      const a4_watch = 210
      const a4_height = 297

      // 根据A4纸的大小，计算出dom相应比例的尺寸
      const pageHeight = Number(((contentWidth / a4_watch) * a4_height).toFixed(4))

      let leftHeight = contentHeight
      let position = 0
      const imgWidth = a4_watch
      // 根据a4比例计算出需要分割的实际dom位置
      const imgHeight = (a4_watch / contentWidth) * contentHeight
      // canvas绘图生成image数据，1.0是质量参数
      const pageData = await canvas.toDataURL('image/jpeg', 1.0)
      // a4大小
      const PDF = new JsPDF({
        unit: 'mm',
        format: [a4_watch, a4_height]
      })
      // 当内容达到a4纸的高度时，分割，将一整块画幅分割出一页页的a4大小，导出pdf
      if (leftHeight < pageHeight) {
        PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
      } else {
        while (leftHeight > 0) {
          PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
          leftHeight -= pageHeight
          position -= a4_height
          if (leftHeight > 0) {
            PDF.addPage()
          }
        }
      }
      // 导出
      PDF.save(`${title}.pdf`)
      resolve(true)
    }).catch(error => {
      console.log('error: ', error)
      reject(false)
    })
  })
}
